import React, { createContext, Dispatch, SetStateAction, useState } from 'react'
import { setBodyOverflow } from './helpers'

interface ContextInterface {
  modalOpened?: boolean
  currentService?: number
  handleModal?: () => void
  setCurrentService?: Dispatch<SetStateAction<number>>
}

export const MyContext = createContext<ContextInterface>({})

const ContextProvider = ({ children }) => {
  const [currentService, setCurrentService] = useState(0)
  const [modalOpened, setModalOpened] = useState(false)

  const handleModal = () => {
    setBodyOverflow()
    setModalOpened(modalOpened => !modalOpened)
  }

  return (
    <MyContext.Provider
      value={{
        modalOpened,
        currentService,
        handleModal,
        setCurrentService,
      }}
    >
      {children}
    </MyContext.Provider>
  )
}

export default ContextProvider
