import { useEffect, useState } from 'react'
import { isTouchDevice } from '../helpers'

const useIsTouchDevice = () => {
  const [touchDevice, setTouchDevice] = useState(true)

  const handleResize = () => {
    setTouchDevice(isTouchDevice())
  }

  useEffect(() => {
    setTouchDevice(isTouchDevice())

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return touchDevice
}

export default useIsTouchDevice
