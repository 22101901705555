export const MEDIA_QUERIES = {
  tablet: 1366,
  mediumTablet: 1024,
  smallTablet: 768,
  mobile: 600,
}

export const REM_VALUES = {
  10: '0.55556rem',
  12: '0.66667rem',
  14: '0.77778rem',
  16: '0.88889rem',
  18: '1rem',
  20: '1.11111rem',
  22: '1.22222rem',
  24: '1.33333rem',
  26: '1.44444rem',
  28: '1.55556rem',
  32: '1.77778rem',
  34: '1.88889rem',
  36: '2rem',
  48: '2.66667rem',
  56: '3.11111rem',
  60: '3.33333rem',
  72: '4rem',
  86: '4.7777778rem',
  96: '5.33333rem',
  116: '6.44444rem',
}

export const ERROR_MESSAGES = {
  REQUIRED: 'This field is required',
  EMAIL: 'This field must be a valid email address',
  MIN_LENGTH: n => `This field must be at least ${n} characters long`,
  MAX_LENGTH: n => `This field must not exceed ${n} characters`,
}
