export const isClientSide = () => typeof window !== 'undefined'

export const isTouchDevice = () => {
  return (
    isClientSide() &&
    ('ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      window.matchMedia('(pointer: coarse)').matches)
  )
}

export const setBodyOverflow = () => {
  if (document.body.style.overflowY === 'scroll') {
    document.body.style.overflowY = 'hidden'
  } else {
    document.body.style.overflowY = 'scroll'
  }
}
