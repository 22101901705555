import React, {
  FC,
  forwardRef,
  useLayoutEffect,
  useImperativeHandle,
  useRef,
} from 'react'
import gsap from 'gsap'

import CursorInsideImg from '../../assets/images/inline/cursor_inside.svg'
import CursorOutsideImg from '../../assets/images/inline/cursor_outside.svg'

import {
  CursorInsideContainer,
  CursorOutsideContainer,
} from './Cursor.components'

interface ForwardRefProps {
  moveTo: (x: number, y: number) => void
  hideCursor: () => void
  showCursor: () => void
  enterLink: () => void
  leaveLink: () => void
}

interface CursorElementProps {
  inside?: boolean
  delay?: number
}

const CursorElement = forwardRef<ForwardRefProps, CursorElementProps>(
  ({ inside, delay }, ref) => {
    const el = useRef()
    const cursorAnimation = useRef(null)
    const q = gsap.utils.selector(el)

    const lineTransformValue = 20

    const linesTransforms = [
      ['+', '+'],
      ['-', '-'],
      ['-', '+'],
      ['+', '-'],
    ]

    useLayoutEffect(() => {
      gsap.set(q('.circle'), {
        transformOrigin: 'center center',
      })

      gsap.set(el.current, { xPercent: -50, yPercent: -50 })

      cursorAnimation.current = gsap
        .timeline({ paused: true })
        .add('tlStart')
        .to(
          q('.circle'),
          {
            scale: 1.1,
            duration: 0.15,
          },
          'tlStart',
        )
        .to(
          q('.cursor-line'),
          {
            x: function (index) {
              return `${linesTransforms[index][0]}${lineTransformValue}`
            },
            y: function (index) {
              return `${linesTransforms[index][1]}${lineTransformValue}`
            },
            duration: 0.15,
          },
          'tlStart',
        )
    }, [])

    useImperativeHandle(
      ref,
      () => {
        return {
          enterLink() {
            cursorAnimation.current.play()
          },
          leaveLink() {
            cursorAnimation.current.reverse()
          },
          hideCursor() {
            gsap.to(el.current, { opacity: 0, duration: 0.3 })
          },
          showCursor() {
            gsap.to(el.current, { opacity: 1, duration: 0.3 })
          },
          moveTo(x, y) {
            gsap.to(el.current, {
              x,
              y,
              delay,
              duration: 0.1,
            })
          },
        }
      },
      [delay],
    )

    return inside ? (
      <CursorInsideContainer ref={el}>
        <CursorInsideImg />
      </CursorInsideContainer>
    ) : (
      <CursorOutsideContainer ref={el}>
        <CursorOutsideImg />
      </CursorOutsideContainer>
    )
  },
)

const Cursor: FC = () => {
  const cursorRefs = useRef([])
  cursorRefs.current = []

  useLayoutEffect(() => {
    cursorRefs.current.forEach(ref =>
      ref.moveTo(innerWidth / 2, innerHeight / 2),
    )

    const onMove = ({ clientX, clientY }) => {
      cursorRefs.current.forEach(ref => ref.moveTo(clientX, clientY))
    }

    const onCursorIn = () => {
      cursorRefs.current.forEach(ref => ref.showCursor())
    }

    const onCursorOut = () => {
      cursorRefs.current.forEach(ref => ref.hideCursor())
    }

    const onLink = () => {
      cursorRefs.current.forEach(ref => ref.enterLink())
    }

    const outOfLink = () => {
      cursorRefs.current.forEach(ref => ref.leaveLink())
    }

    document.querySelectorAll('.cursor-reactive').forEach(elem => {
      elem.addEventListener('mouseenter', onLink)
      elem.addEventListener('mouseleave', outOfLink)
    })

    window.addEventListener('mousemove', onMove)
    document.addEventListener('mouseleave', onCursorOut)
    document.addEventListener('mouseenter', onCursorIn)

    return () => {
      window.removeEventListener('mousemove', onMove)
      document.removeEventListener('mouseleave', onCursorOut)
      document.removeEventListener('mouseenter', onCursorIn)
      document.querySelectorAll('.cursor-reactive').forEach(elem => {
        elem.removeEventListener('mouseenter', onLink)
        elem.removeEventListener('mouseleave', outOfLink)
      })
    }
  }, [])

  const addCursorRef = ref => ref && cursorRefs.current.push(ref)

  return (
    <>
      <CursorElement ref={addCursorRef} />
      <CursorElement delay={0.01} inside ref={addCursorRef} />
    </>
  )
}

export default Cursor
