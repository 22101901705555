import styled, { css, keyframes } from 'styled-components'

import { MEDIA_QUERIES, REM_VALUES } from '../../utils/constants'

const buttonBackgroundAnimation = keyframes`
0% {
  width: 0;
}
100% {
  width: 100%;
}
`

export const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding: 0 16px;
  }
`

export const CTAButton = styled.button<{
  secondary?: boolean
  fullWidth?: boolean
  large?: boolean
  loading?: string | undefined
}>`
  font-size: ${REM_VALUES[12]};
  line-height: ${REM_VALUES[18]};
  font-weight: 700;
  background: ${({ theme }) => theme.colors.red.cta};
  padding: 8px 24px;
  border: 1px solid ${({ theme }) => theme.colors.red.ctaLight};
  box-shadow: 0px 0px 3px #e13942, 0px 0px 16px #ff242c,
    inset 0px 0px 5px #ff2a1c;
  border-radius: 2px;
  text-transform: uppercase;
  transition: background 0.2s ease-in-out;
  color: #000;
  position: relative;

  &:hover {
    background: ${({ theme }) => theme.colors.red.ctaLight};
  }

  ${({ loading }) =>
    loading === 'true' &&
    css`
      &:before {
        display: block;
        content: '';
        position: absolute;
        width: 0;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(143, 255, 249, 0.3);
        z-index: -1;
        opacity: 0.5;
        animation: ${buttonBackgroundAnimation} 2s ease-in-out forwards;
      }
    `}

  ${({ secondary }) =>
    secondary &&
    css`
      border: 1px solid #16d6e2;
      box-shadow: inset 0px 0px 5px #16d6e2;
      filter: drop-shadow(0px 0px 3px #256e73) drop-shadow(0px 0px 6px #16d6e2);
      background: transparent;
      color: #ffffff;
      text-shadow: 0px 0px 3px #085892, 0px 0px 4px #16d6e2;

      &:hover {
        background: rgba(143, 255, 249, 0.3);
      }

      &:disabled {
        opacity: 0.5;
      }

      &:disabled:hover {
        background: transparent;
      }
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

    ${({ large }) =>
    large &&
    css`
      padding: 16px 24px;
    `}
`
