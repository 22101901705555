import React, { FC, ReactNode } from 'react'
import { FieldError } from 'react-hook-form'

import { Caption } from '../Typography/Typography.components'
import { StyledInputWrapper } from './Form.components'

interface InputWrapperProps {
  inputName: string
  children: ReactNode
  error?: FieldError
  disabled?: boolean
}

const InputWrapper: FC<InputWrapperProps> = ({
  inputName,
  children,
  error,
  disabled,
}) => {
  return (
    <StyledInputWrapper disabled={disabled} error={!!error}>
      <Caption>{inputName}</Caption>
      {children}
      {!!error && <Caption>{error?.message}</Caption>}
    </StyledInputWrapper>
  )
}

export default InputWrapper
