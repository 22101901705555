import styled, { css, keyframes } from 'styled-components'

const arrowAnimation = keyframes`
  from {
    transform: translateY(0);
  } 
  to {
    transform: translateY(5px);
  }
`

const CursorStyles = css`
  position: fixed;
  pointer-events: none;
  z-index: 200;
  pointer-events: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
`

export const CursorOutsideContainer = styled.div`
  ${CursorStyles}

  .cursor-arrow-top {
    animation: ${arrowAnimation} 1s ease-in-out infinite alternate;
  }

  .cursor-arrow-bottom {
    animation: ${arrowAnimation} 1s ease-in-out infinite alternate-reverse;
  }
`

export const CursorInsideContainer = styled.div`
  ${CursorStyles}
`
