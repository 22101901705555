import styled from 'styled-components'
import { Wrapper } from '../Shared/Shared.components'
import ModalOutline from '../../assets/images/inline/modal.svg'
import PlusVector from '../../assets/images/inline/plus.svg'
import { MEDIA_QUERIES } from '../../utils/constants'
import { Button, H4 } from '../Typography/Typography.components'

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: tranparent;
  z-index: 10;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-height: ${MEDIA_QUERIES.mobile}px) {
    padding-top: 32px;
    padding-bottom: 32px;
    overflow: scroll;
  }
`

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  background: ${({ theme }) => theme.colors.bodyBackground};
  opacity: 0;
  visibility: hidden;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 100%;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @media (max-height: ${MEDIA_QUERIES.mobile}px) {
    display: block;
  }
`

export const ModalWindow = styled.div`
  position: relative;
  width: 100%;
  max-width: 674px;
  padding: 56px 24px 80px 24px;
  opacity: 0;
  visibility: hidden;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding: 32px 16px 48px 16px;
  }
`

export const ExitButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 24px;
  transition: all 0.2s ease-in-out;
  z-index: 2;

  &:hover {
    transform: scale(1.1);
  }
`

export const ModalContent = styled.div`
  max-width: 434px;
  width: 80%;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  ${H4} {
    text-shadow: 0px 0px 3px #085892, 0px 0px 8px #16d6e2;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    max-width: 300px;
  }
`

export const FormBody = styled.form`
  margin: 0;

  .error-message {
    margin: 0;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.red.ctaLight};
  }
`

export const ModalWindowOutline = styled(ModalOutline)`
  position: absolute;
  top: -10%;
  left: -7%;
  bottom: 0;
  right: 0;
  width: 114%;
  height: 120%;
`

export const Plus = styled(PlusVector)`
  position: absolute;

  &.plus-one {
    top: 0;
    left: 0;
    transform: translate(-100%, -100%);

    @media (max-width: ${MEDIA_QUERIES.mobile}px) {
      transform: scale(0.5) translate(-150%, -150%);
    }
  }

  &.plus-two {
    bottom: 0;
    right: 0;
    transform: translate(100%, 100%);

    @media (max-width: ${MEDIA_QUERIES.mobile}px) {
      transform: scale(0.5) translate(150%, 150%);
    }
  }
`
