import styled, { css } from 'styled-components'
import { REM_VALUES } from '../../utils/constants'
import { Caption } from '../Typography/Typography.components'

export const StyledInputWrapper = styled.div<{
  error?: boolean
  disabled?: boolean
}>`
  display: flex;
  flex-direction: column;
  margin: 16px 0;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
    `}

  > input {
    height: 40px;
  }

  > textarea {
    height: 88px;
    resize: none;
  }

  > input,
  > textarea {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    padding: 12px;
    font-family: 'Space Mono';
    font-style: normal;
    font-weight: 400;
    font-size: ${REM_VALUES[12]};
    line-height: ${REM_VALUES[16]};
    letter-spacing: 0.4px;
    color: ${({ theme }) => theme.colors.white};
    transition: border 0.2s ease-in-out;

    :hover {
      border: 1px solid rgba(22, 214, 226, 0.4);
    }

    :focus {
      border: 1px solid #16d6e2;
      outline: none;
    }

    &:placeholder {
      color: ${({ theme }) => theme.colors.white};
      opacity: 0.4;
    }

    &:not(:placeholder-shown) {
      border-color: ${({ theme }) => theme.colors.white};

      ${({ error }) =>
        error &&
        css`
          border-color: ${({ theme }) => theme.colors.red.cta};
        `}
    }

    ${({ error }) =>
      error &&
      css`
        border-color: ${({ theme }) => theme.colors.red.cta};
      `}
  }

  ${Caption} {
    margin: 0;

    &:nth-of-type(1) {
      margin-bottom: 8px;
    }

    &:nth-of-type(2) {
      margin-top: 8px;
      color: ${({ theme }) => theme.colors.red.ctaLight};
    }
  }
`
