import { DefaultTheme } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      white: string
      blue: string
      bodyBackground: string
      red: {
        cta: string
        ctaDark: string
        ctaLight: string
      }
    }
  }
}

export const theme: DefaultTheme = {
  colors: {
    white: '#FFF',
    blue: '#16D6E2',
    bodyBackground: '#0C111B',
    red: {
      cta: '#FF4A51',
      ctaDark: '#DD2736',
      ctaLight: '#FF8D86',
    },
  },
}
