import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import { useForm } from 'react-hook-form'

import { MyContext } from '../../utils/Context'
import InputWrapper from '../Form/InputWrapper'
import { CTAButton } from '../Shared/Shared.components'
import { Caption, H4 } from '../Typography/Typography.components'
import {
  ExitButton,
  FormBody,
  ModalContent,
  ModalWindow,
  ModalWindowOutline,
  Plus,
  StyledModal,
  StyledWrapper,
} from './Modal.components'
import { ERROR_MESSAGES } from '../../utils/constants'

const Modal: FC = () => {
  const context = useContext(MyContext)
  const modalRef = useRef(null)
  const modalWindowRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  })
  const [loading, setLoading] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestError, setRequestError] = useState(false)

  const handleFormSubmit = data => {
    setLoading(true)
    setRequestError(false)

    const formData = new FormData()

    formData.append('form-name', 'Contact')
    Object.keys(data).forEach(name => {
      if (typeof data[name] === 'object') {
        formData.append(name, data[name][0])
      } else {
        formData.append(name, data[name])
      }
    })

    setTimeout(() => {
      fetch('/contact', {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (response.ok) {
            setLoading(false)
            setRequestSuccess(true)
          } else {
            setLoading(false)
            setRequestError(true)
          }
        })
        .catch(e => {
          setLoading(false)
          setRequestError(true)
        })
    }, 2000)
  }

  useEffect(() => {
    if (context.modalOpened) {
      const modalWrapper = modalRef.current.querySelector('.modal-wrapper')

      gsap
        .timeline()
        .to(modalWrapper, {
          autoAlpha: 1,
          duration: 0.3,
        })
        .to(
          modalWindowRef.current,
          {
            duration: 0.5,
            autoAlpha: 1,
          },
          '+=0.3',
        )
    }
  }, [context.modalOpened])

  const handleModalClose = () => {
    gsap
      .timeline()
      .to(modalWindowRef.current, {
        duration: 0.5,
        autoAlpha: 0,
      })
      .to(
        modalRef.current,
        {
          autoAlpha: 0,
          duration: 0.3,
          onComplete: () => {
            context.handleModal()
            setLoading(false)
            setRequestSuccess(false)
            setRequestError(false)
            reset()
          },
        },
        '+=0.3',
      )
  }

  if (!context.modalOpened) {
    return null
  }

  return (
    <StyledModal ref={modalRef}>
      <StyledWrapper className="modal-wrapper">
        <ModalWindow ref={modalWindowRef}>
          <ExitButton className="cursor-reactive" onClick={handleModalClose}>
            Close
          </ExitButton>
          <ModalContent>
            <H4>Talk to us</H4>
            <FormBody
              name="Contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              autoComplete="off"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <input type="hidden" name="form-name" value="Contact" />

              <InputWrapper
                error={errors.email}
                disabled={requestSuccess || requestError}
                inputName="E-mail address"
              >
                <input
                  aria-label="Email"
                  disabled={requestSuccess || requestError}
                  name="email"
                  {...register('email', {
                    required: { value: true, message: ERROR_MESSAGES.REQUIRED },
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  className="cursor-reactive"
                  placeholder="Please enter e-mail"
                />
              </InputWrapper>
              <InputWrapper
                error={errors.message}
                disabled={requestSuccess || requestError}
                inputName="Message"
              >
                <textarea
                  disabled={requestSuccess || requestError}
                  aria-label="Message"
                  name="message"
                  {...register('message', {
                    required: { value: true, message: ERROR_MESSAGES.REQUIRED },
                  })}
                  className="cursor-reactive"
                  placeholder="Please enter message"
                />
              </InputWrapper>
              {requestError && (
                <Caption className="error-message">
                  Failed, please try again later.
                </Caption>
              )}
              <CTAButton
                disabled={requestSuccess || requestError || loading}
                loading={loading ? 'true' : undefined}
                type="submit"
                className="cursor-reactive"
                secondary
                fullWidth
              >
                {loading
                  ? 'Sending'
                  : requestSuccess
                  ? 'Message sent'
                  : 'Send message'}
              </CTAButton>
            </FormBody>
          </ModalContent>
          <ModalWindowOutline />
          <Plus className="plus-one" />
          <Plus className="plus-two" />
        </ModalWindow>
      </StyledWrapper>
    </StyledModal>
  )
}

export default Modal
