import React from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../styles/theme'
import GlobalStyles from '../styles/GlobalStyles'

import ContextProvider from '../utils/Context'
import useIsTouchDevice from '../utils/hooks/useIsTouchDevice'

import Cursor from './Cursor/Cursor'
import Modal from './Modal/Modal'

const Layout: React.FC = ({ children }) => {
  const isTouchDevice = useIsTouchDevice()

  return (
    <ThemeProvider theme={theme}>
      <ContextProvider>
        <GlobalStyles />
        <Modal />
        {!isTouchDevice && <Cursor />}
        {children}
      </ContextProvider>
    </ThemeProvider>
  )
}

export default Layout
