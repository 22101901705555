import styled from 'styled-components'
import { MEDIA_QUERIES, REM_VALUES } from '../../utils/constants'

export const Badge = styled.p`
  font-size: ${REM_VALUES[10]};
  line-height: ${REM_VALUES[16]};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`

export const Overline = styled.p`
  font-size: ${REM_VALUES[10]};
  line-height: ${REM_VALUES[16]};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1.5px;
`

export const Caption = styled.p`
  font-size: ${REM_VALUES[12]};
  line-height: ${REM_VALUES[16]};
  font-weight: 400;
  letter-spacing: 0.4px;
`

export const Button = styled.p`
  font-size: ${REM_VALUES[12]};
  line-height: ${REM_VALUES[18]};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`

export const Body1 = styled.p`
  font-size: ${REM_VALUES[16]};
  line-height: ${REM_VALUES[24]};
  font-weight: 400;
  letter-spacing: 0.44px;
`

export const Body2 = styled.p`
  font-size: ${REM_VALUES[14]};
  line-height: ${REM_VALUES[20]};
  font-weight: 400;
  letter-spacing: 0.25px;
  margin: 0;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    font-size: ${REM_VALUES[12]};
    line-height: ${REM_VALUES[18]};
  }
`

export const Subtitle1 = styled.p`
  font-family: 'Oswald', georgia, serif;
  font-size: ${REM_VALUES[16]};
  line-height: ${REM_VALUES[20]};
  font-weight: 400;
  letter-spacing: 0.15px;
  text-transform: uppercase;
`

export const Subtitle2 = styled.p`
  font-family: 'Oswald', georgia, serif;
  font-size: ${REM_VALUES[14]};
  line-height: ${REM_VALUES[24]};
  font-weight: 500;
  letter-spacing: 0.1px;
`

export const H1 = styled.h1`
  font-size: ${REM_VALUES[96]};
  line-height: ${REM_VALUES[116]};
  font-weight: 400;
  text-transform: uppercase;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    font-size: ${REM_VALUES[72]};
    line-height: ${REM_VALUES[86]};
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    font-size: ${REM_VALUES[48]};
    line-height: ${REM_VALUES[56]};
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    font-size: ${REM_VALUES[34]};
    line-height: ${REM_VALUES[36]};
  }
`

export const H2 = styled.h2`
  font-size: ${REM_VALUES[60]};
  line-height: ${REM_VALUES[72]};
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: -0.5px;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    font-size: ${REM_VALUES[48]};
    line-height: ${REM_VALUES[56]};
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    font-size: ${REM_VALUES[34]};
    line-height: ${REM_VALUES[36]};
  }
`

export const H3 = styled.h3`
  font-size: ${REM_VALUES[48]};
  line-height: ${REM_VALUES[58]};
  font-weight: 500;
  text-transform: uppercase;
`

export const H4 = styled.h4`
  font-size: ${REM_VALUES[34]};
  line-height: ${REM_VALUES[36]};
  font-weight: 500;
  letter-spacing: 0.25px;
  text-transform: uppercase;
`

export const H5 = styled.h5`
  font-size: ${REM_VALUES[24]};
  line-height: ${REM_VALUES[32]};
  font-weight: 500;
`

export const H6 = styled.h6`
  font-size: ${REM_VALUES[20]};
  line-height: ${REM_VALUES[24]};
  font-weight: 700;
  letter-spacing: 0.15px;
`
